<template>
	<b-row>
		<b-col cols="10">
			<b-row>
				<b-col class="d-flex align-items-center" cols="3">
					<span style="flex: 3">开始时间</span>
					<b-form-input style="flex: 7" type="date"></b-form-input>
				</b-col>
				<b-col class="d-flex align-items-center" cols="3">
					<span style="flex: 3">结束时间</span>
					<b-form-input style="flex: 7" type="date"></b-form-input>
				</b-col>
				<b-col cols="6">
					<b-button>检索</b-button>
				</b-col>
			</b-row>
		</b-col>
		<b-col cols="2">
			<div class="v-img"></div>
			<p class="py-1">访客照片</p>
		</b-col>
	</b-row>
</template>

<script>
	export default {
		name: "index",
	};
</script>

<style lang="scss" scoped>
	.v-img {
		width: 100%;
		height: 0;
		padding-bottom: 100%;
		background: #cccccc;
	}
</style>
